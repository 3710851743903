import React, { useEffect, useState } from "react";
import { navigate, graphql } from "gatsby";
import SEO from "../components/SEO/seo";
import Layout from "../components/Layout";
import HomePage from "../components/Home/home";

const IndexPage = ({ data, location }) => {
  const promotionsData = data?.allPromotions?.nodes;
  const categoriesData = data?.allCategories?.nodes;
  const brandsData = data?.allBrands?.nodes;
  const termsData = data?.allTerms?.nodes;
  const pocsData = data?.allContacts?.nodes ?? [];


  return (
    <>
      <SEO title="On premise" />
      <Layout allBrands={brandsData}>
        <HomePage
          location={location}
          allCategories={categoriesData}
          allPromotions={promotionsData}
          allBrands={brandsData}
          allTerms={termsData}
          pocsData={pocsData}
        />
      </Layout>
    </>
  );
};

export default IndexPage;

export const data = graphql`
  query IndexQueryTemplate {
    allPromotions {
      nodes {
        body
        brand
        active
        img
        id
        type
        url
        category
        order
        region
        hours
        days
      }
    }

    allCategories {
      nodes {
        name
        order
      }
    }

    allBrands {
      nodes {
        active
        bannerTitle
        color
        backgroundColor
        imgBanner
        imgBrand
        name
        ageGateBackgroundImg
        ageGateMobileBackgroundImg
        ageGateLogoImg
        ageGateText
        ageGateColor
        ageGateUseColor
      }
    }
    allTerms {
      nodes {
        brand
        consumers
        shops
      }
    }
  }
`;
